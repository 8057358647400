<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Profile utilisateur">

          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  md="6"
                  lg="6"
                >
                  <b-form-group label="Nom">
                    <validation-provider
                      #default="{ errors }"
                      name="nom"
                      rules="required"
                    >
                      <b-form-input
                        v-model="webUser.lastName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nom"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  lg="6"
                >
                  <b-form-group label="Prénom">
                    <validation-provider
                      #default="{ errors }"
                      name="Prénom"
                      rules="required"
                    >
                      <b-form-input
                        v-model="webUser.firstName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Prénom"
                        readonly
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  lg="6"
                >
                  <!-- Old password -->
                  <b-form-group label="Ancien mot de passe">
                    <validation-provider
                      #default="{ errors }"
                      name="Mot de passe"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="oldpassword"
                          v-model="webUser.oldPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  lg="6"
                >
                  <!-- new password -->
                  <b-form-group label="Nouveau mot de passe">
                    <validation-provider
                      #default="{ errors }"
                      name="nouveau mot de passe"
                      vid="newpassword"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="newpassword"
                          v-model="webUser.newPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- confirm password -->
                <!-- <b-col
                  md="6"
                  lg="6"
                >

                  <b-form-group label="confirmer mot de passe">
                    <validation-provider
                      #default="{ errors }"
                      name="confirm Mot de passe"
                      vid="confirmpassword"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password"
                          v-model="webUser.confirmPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null"
                          name="confirmpassword"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

              </b-row>
            </b-form>
          </validation-observer>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="updatePassword($event)"
          >
            Modifier le mot de passe
          </b-button>

        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BForm, BFormSelect, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'jspdf-autotable'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      userData: getUserData(),
      dir: false,
      showOverlay: true,
      desableButton: false,
      zones: [
        { value: null, text: 'Veuillez sélectionner un village ou quartier' },
      ],
      arrondissements: [
        { value: null, text: 'Veuillez sélectionner un arrondissement' },
      ],
      communes: [
        { value: null, text: 'Veuillez sélectionner une commune' },
      ],
      departements: [
        { value: null, text: 'Veuillez sélectionner un departement' },
      ],
      balisecolumns: [
        {
          label: 'Hameau',
          field: 'hameau',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Village',
          field: 'zone.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Arrondissement',
          field: 'arrondissement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Commune',
          field: 'commune.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Departement',
          field: 'departement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Longitude',
          field: 'longitude',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Longitude',
          },
        },
        {
          label: 'Latitude',
          field: 'latitude',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par nom',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      webUser: {
        id: getUserData().userId,
        lastName: getUserData().lastName,
        firstName: getUserData().firstName,
        email: getUserData().email,
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    balises() {
      return this.$store.state.mainAppStore.balises
    },

    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  async created() {
    console.log(this.userData)

    try {
      await this.getDepartementList()
    } catch (error) {
      console.log(error.response)
    }
  },
  methods: {

    async getDepartementList() {
      this.showOverlay = true
      const response = await this.$store.dispatch('mainAppStore/fetchDepartementsListItems')
      console.log(getUserData());
      
      console.log(response.data)
      if (response.data.length > 0) {
        for (let index = 0; index < response.data.length; index++) {
          const departement = {
            value: response.data[index].id,
            text: response.data[index].name,
          }
          this.departements.push(departement)
        }
        this.showOverlay = false
      }
    },
    updatePassword(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.desableButton = true
          console.log(this.webUser)
          this.$store.dispatch('authStore/updatePassword', this.webUser).then(response => {
            console.log(response.data)
            this.$swal({
              title: 'Succes',
              html: 'mot de passe modifié avec succes',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
            this.logout()
          })
            .catch(err => {
              this.desableButton = false
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response)
            })
        }
      })
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('fullUserData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userInfo')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' }).catch(() => {})
    },
  },
}
</script>
